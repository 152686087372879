import React, { useMemo } from 'react';
import _t from 'counterpart';
import { CBadge, CCard, CCardBody, CCardHeader, CCardSubtitle, CCardTitle, CCol, CRow } from '@coreui/react';
import { useParams } from 'react-router-dom';
import { factorToPercentage } from '../../helpers';
import PaginationTable from '../../components/PaginationTable';
import { IChallengeLimit, IChallengeObjective } from '../propTrading/types';
import PropChallengeAccountStatusBadge from './PropChallengeAccountStatusBadge';

export interface IProps {
	limits?: Array<IChallengeLimit>;
	objectives?: Array<IChallengeObjective>;
}

const PropFundedAccountInfo = ({ limits, objectives }: IProps) => {
	const tableFields = useMemo(
		() => [
			{
				key: 'tradingObjective',
				label: _t('prop-challenges.trading-objective'),
				sorter: true,
			},
			{
				key: 'Result',
				label: _t('prop-challenges.result'),
				sorter: false,
			},
			{
				key: 'Summary',
				label: _t('prop-challenges.summary'),
				sorter: false,
			},
		],
		[]
	);

	const scopedSlots = useMemo(() => {
		return {
			tradingObjective: ({ name }: IChallengeObjective) => <td>{returnTranslatedName(name)}</td>,
			Result: ({ target, current, isFactor }: IChallengeObjective) => (
				<td>{returnFormattedResult(isFactor, current, target)}</td>
			),
			Summary: ({ status }: IChallengeObjective) => <PropChallengeAccountStatusBadge status={status} />,
		};
	}, []);

	const tableFieldsLimits = useMemo(
		() => [
			{
				key: 'limit',
				label: _t('prop-challenges.limit'),
				sorter: true,
			},
			{
				key: 'Result',
				label: _t('prop-challenges.result'),
				sorter: false,
			},
			{
				key: 'Summary',
				label: _t('prop-challenges.has-been-breached'),
				sorter: false,
			},
		],
		[]
	);

	const returnTranslatedName = (name: string) => {
		switch (name) {
			case 'minTradingDays':
				return _t('prop-challenges.min-trading-days');
			case 'profitTargetFactor':
				return _t('wallet.profit-target-factor');
			case 'maxTradingDays':
				return _t('prop-challenges.max-trading-days');
			case 'maxInactivityDays':
				return _t('prop-challenges.max-inactivity-days');
			case 'maxDailyDrawdownFactor':
				return _t('wallet.max-daily-drawdown');
			case 'maxTotalDrawdownFactor':
				return _t('wallet.max-total-drawdown');
			default:
				return name;
		}
	};

	const returnFormattedResult = (isFactor: boolean, current: number, max: number) => {
		return isFactor ? `${factorToPercentage(current)}/${factorToPercentage(max)}` : `${current}/${max}`;
	};

	const scopedSlotsLimits = useMemo(() => {
		return {
			limit: ({ name }: IChallengeLimit) => <td>{returnTranslatedName(name)}</td>,
			Result: ({ limit, current, isFactor }: IChallengeLimit) => (
				<td>{returnFormattedResult(isFactor, current, limit)}</td>
			),
			Summary: ({ isBreached }: IChallengeLimit) => (
				<CBadge color={isBreached ? 'danger' : 'primary'}>{isBreached ? 'YES' : 'NO'}</CBadge>
			),
		};
	}, []);

	return (
		<>
			<CRow>
				<CCol lg={6}>
					<CCard>
						<CCardHeader className="pb-0">
							<div className="float-left">
								<CCardTitle className="float-left mb-1">{_t('prop-challenges.trading-objectives-title')}</CCardTitle>
							</div>
						</CCardHeader>
						<CCardBody>
							<PaginationTable
								tableFields={tableFields}
								scopedSlots={scopedSlots}
								data={objectives ?? []}
								loading={false}
								pages={0}
							/>
						</CCardBody>
					</CCard>
				</CCol>
				<CCol lg={6}>
					<CCard>
						<CCardHeader className="pb-0">
							<div className="float-left">
								<CCardTitle className="float-left mb-1">{_t('prop-challenges.limits')}</CCardTitle>
							</div>
						</CCardHeader>
						<CCardBody>
							<PaginationTable
								tableFields={tableFieldsLimits}
								scopedSlots={scopedSlotsLimits}
								data={limits ?? []}
								loading={false}
								pages={0}
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
		</>
	);
};

export default PropFundedAccountInfo;
