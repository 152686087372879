import React, { useState } from 'react';
import _t from 'counterpart';
import { useQuery } from 'react-query';
import { CForm, CRow, CCol, CFormGroup, CInput } from '@coreui/react';
import CustomSelect, { ISelectOption } from '../../components/CustomSelect';
import { useAppDispatch, useAppSelector } from '../../helpers/customHooks';
import { IWalletsFilters } from '../../reducers/filtersReducer';
import { setWalletsFilters } from '../../actions';
import { shallowEqual } from 'react-redux';
import { currencies, tradingTypesOptions, types as modes, typesOptions } from './types';
import { getPropFunnels, loadGroups } from '../../services/BackendService';
import { extractErrorMessage } from '../../helpers';
import FilterInputHeader from '../../components/FilterInputHeader';
import toast from 'react-hot-toast';
import { IGroup } from '../groups/types';
import { IPropFunnel } from '../propTrading/propFunnels/types';

interface IProps {
	show: boolean;
}

const WalletsFilter = ({ show }: IProps) => {
	const [groups, setGroups] = useState<Array<ISelectOption>>([]);
	const [propFunnels, setPropFunnels] = useState<Array<ISelectOption>>([]);

	const dispatch = useAppDispatch();
	const activeFilters = useAppSelector((state) => state.filters.walletsFilters, shallowEqual);

	useQuery(['filter-groups'], () => loadGroups(), {
		onSuccess: (data: Array<IGroup>) => {
			const groupsToOptions = data.map((g: IGroup) => {
				return { label: g.name, value: g.id.toString() };
			});
			setGroups(groupsToOptions);
		},
		onError: (err: any) => {
			const msg = extractErrorMessage(err);
			toast.error(msg);
		},
	});

	useQuery(['filter-funnels'], () => getPropFunnels(), {
		onSuccess: (data: Array<IPropFunnel>) => {
			const propFunnelsToOptions = data.map((pf: IPropFunnel) => {
				return { label: pf.id, value: pf.id };
			});
			setPropFunnels(propFunnelsToOptions);
		},
		onError: (err: any) => {
			const msg = extractErrorMessage(err);
			toast.error(msg);
		},
	});

	const setFilters = (filters: Partial<IWalletsFilters>) => {
		dispatch(setWalletsFilters(filters));
	};

	const resetSelectedCurrencies = () => {
		setFilters({
			currency: [],
		});
	};

	const handleCurrenciesChanged = (currencies: any) => {
		setFilters({
			currency: currencies,
		});
	};

	const handleFrom = (e: any) => {
		const amount = e.target.value;

		setFilters({
			leverageFrom: amount || undefined,
		});
	};

	const handleTo = (e: any) => {
		const amount = e.target.value;

		setFilters({
			leverageTo: amount || undefined,
		});
	};

	const resetAmountRange = () => {
		setFilters({
			leverageFrom: '',
			leverageTo: '',
		});
	};

	const handleModeChanged = (mode: any) => {
		setFilters({ mode });
	};

	const handleTypeChanged = (type: any) => {
		setFilters({ type });
	};

	const handlePropFunnelChanged = (propFunnel: any) => {
		setFilters({ propFunnel });
	};

	const resetMode = () => {
		setFilters({ mode: null });
	};

	const resetType = () => {
		setFilters({ type: null });
	};

	const resetPropFunnel = () => {
		setFilters({ propFunnel: null });
	};

	const handleGroupsChanged = (groups: any) => {
		setFilters({ groups });
	};

	const handleTradingTypeChanged = (tradingType: any) => {
		setFilters({ tradingType });
	};

	const resetGroup = () => {
		setFilters({
			groups: [],
		});
	};

	const resetTradingType = () => {
		setFilters({
			tradingType: null,
		});
	};

	return (
		<CForm className={`w-100 filters ${!show ? 'd-none' : ''}`}>
			<CRow>
				<CCol md={4}>
					<CFormGroup>
						<FilterInputHeader labelText={_t('wallets.filters.currencies')} resetFilter={resetSelectedCurrencies} />
						<CustomSelect
							value={activeFilters.currency ?? null}
							options={currencies}
							onChange={handleCurrenciesChanged}
							isMulti
							isClearable={false}
							whiteBackground
						/>
					</CFormGroup>
				</CCol>
				<CCol md={4}>
					<CFormGroup>
						<FilterInputHeader labelText={_t('wallets.leverage')} resetFilter={resetAmountRange} />
						<div className="d-flex justify-content-between">
							<CInput
								type="number"
								value={activeFilters.leverageFrom}
								onChange={handleFrom}
								className="mr-1"
								placeholder={_t('global.from')}
							/>
							<CInput
								type="number"
								value={activeFilters.leverageTo}
								onChange={handleTo}
								className="ml-1"
								placeholder={_t('global.to')}
							/>
						</div>
					</CFormGroup>
				</CCol>
				<CCol md={4}>
					<CFormGroup>
						<FilterInputHeader labelText={_t('wallets.mode')} resetFilter={resetMode} />
						<CustomSelect
							value={activeFilters.mode}
							options={modes}
							onChange={handleModeChanged}
							isClearable={false}
							whiteBackground
						/>
					</CFormGroup>
				</CCol>
				<CCol md={4}>
					<CFormGroup>
						<FilterInputHeader labelText={_t('wallets.trading-type')} resetFilter={resetTradingType} />
						<CustomSelect
							value={activeFilters.tradingType}
							options={tradingTypesOptions}
							onChange={handleTradingTypeChanged}
							isClearable={false}
							whiteBackground
						/>
					</CFormGroup>
				</CCol>
				<CCol md={4}>
					<CFormGroup>
						<FilterInputHeader labelText={_t('wallets.type')} resetFilter={resetType} />
						<CustomSelect
							value={activeFilters.type}
							options={typesOptions}
							onChange={handleTypeChanged}
							isClearable={false}
							whiteBackground
						/>
					</CFormGroup>
				</CCol>
				<CCol md={4}>
					<CFormGroup>
						<FilterInputHeader labelText={_t('wallets.prop-funnel')} resetFilter={resetPropFunnel} />
						<CustomSelect
							value={activeFilters.propFunnel}
							options={propFunnels}
							onChange={handlePropFunnelChanged}
							isClearable={false}
							whiteBackground
						/>
					</CFormGroup>
				</CCol>
				<CCol md={4}>
					<CFormGroup>
						<FilterInputHeader labelText={_t('wallets.group')} resetFilter={resetGroup} />
						<CustomSelect
							value={activeFilters.groups}
							options={groups}
							onChange={handleGroupsChanged}
							isMulti
							whiteBackground
						/>
					</CFormGroup>
				</CCol>
			</CRow>
		</CForm>
	);
};

export default React.memo(WalletsFilter);
