import React, { ChangeEvent, useEffect, useState } from 'react';
import {
	CButton,
	CFormGroup,
	CFormText,
	CInput,
	CInputGroup,
	CInputGroupPrepend,
	CInputGroupText,
	CLabel,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
	CSelect,
} from '@coreui/react';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import _t from 'counterpart';
import { IWallet } from '../wallets/types';
import { extractErrorMessage, findErrorFromValidation } from '../../helpers';
import { useMutation, useQueryClient } from 'react-query';
import { createWalletTransfer } from '../../services/BackendService';
import Balance from './CustomerWalletBalance';
import Loading from '../../components/Loading';
import Error from '../../components/Error';
import { useCustomerWallets } from './apiQueries';
import toast from 'react-hot-toast';
import { isWalletAllowedToTransfer } from './helpers';

interface IProps {
	show: boolean;
	onClose: (refetchBalances?: boolean) => void;
	customerId: string;
}

interface ITransferParams {
	sourceWalletId: number;
	targetWalletId: number;
	amount: number;
}

const WalletTransferModal = ({ show, onClose, customerId }: IProps) => {
	const [sourceWalletId, setSourceWallet] = useState<number>();
	const [targetWalletId, setTargetWallet] = useState<number>();
	const [amount, setAmount] = useState<number | null>(null);

	const queryClient = useQueryClient();

	const walletsQuery = useCustomerWallets(customerId);

	const transferMutation = useMutation(
		['wallet-transfer'],
		({ sourceWalletId, targetWalletId, amount }: ITransferParams) =>
			createWalletTransfer(sourceWalletId, targetWalletId, amount),
		{
			onError: (error: any) => {
				if (error.response?.status !== 422) {
					toast.error(extractErrorMessage(error));
				}
			},
			onSuccess: () => {
				setAmount(null);
				toast.success(_t('customer.transfer.transfer-successful'));
				queryClient.invalidateQueries(['wallet-balance', sourceWalletId]);
				queryClient.invalidateQueries(['wallet-balance', targetWalletId]);
				queryClient.invalidateQueries(['customer-transactions', customerId]);
				onClose();
			},
		}
	);

	useEffect(() => {
		if (walletsQuery.data && walletsQuery.isSuccess) {
			const wallets = walletsQuery.data;
			const defaultSourceWallet = wallets.find((w) => isWalletAllowedToTransfer(w));
			const defaultTargetWallet = wallets.find((w) => isWalletAllowedToTransfer(w) && w.id !== defaultSourceWallet?.id);

			if (defaultSourceWallet) {
				setSourceWallet(defaultSourceWallet.id);
			}
			if (defaultTargetWallet) {
				setTargetWallet(defaultTargetWallet.id);
			}
		}
	}, [walletsQuery.data, walletsQuery.isSuccess]);

	const onSourceWalletChange = (e: ChangeEvent<HTMLSelectElement>) => {
		const { value } = e.target;
		const sourceWalletId = Number(value);
		setSourceWallet(sourceWalletId);

		if (sourceWalletId === targetWalletId) {
			const wallets = walletsQuery.data;
			const target = wallets?.find((w: IWallet) => isWalletAllowedToTransfer(w) && w.id !== sourceWalletId);
			if (target) {
				setTargetWallet(target?.id);
			}
		}
	};

	const onTargetWalletChange = (e: ChangeEvent<HTMLSelectElement>) => {
		const { value } = e.target;
		const targetWalletId = Number(value);
		setTargetWallet(targetWalletId);

		if (targetWalletId === sourceWalletId) {
			console.log('tu sam');
			const wallets = walletsQuery.data;
			const source = wallets?.find((w: IWallet) => isWalletAllowedToTransfer(w) && w.id !== targetWalletId);
			if (source) {
				setSourceWallet(source?.id);
			}
		}
	};

	const handleAmountChanged = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setAmount(Number(value));
	};

	const findError = (paramName: string) => {
		return findErrorFromValidation(transferMutation.error, paramName);
	};

	const invokeMutation = () => {
		if (sourceWalletId && targetWalletId) {
			transferMutation.mutate({
				sourceWalletId: sourceWalletId,
				targetWalletId: targetWalletId,
				amount: amount ? Number(amount) : 0,
			});
		}
	};

	const closeHandler = () => {
		setAmount(null);
		transferMutation.reset();
		onClose();
	};

	if (walletsQuery.isLoading || walletsQuery.isIdle) {
		return (
			<CModal show={show} onClose={closeHandler}>
				<CModalBody>
					<Loading />
				</CModalBody>
			</CModal>
		);
	}

	if (walletsQuery.isError) {
		return (
			<CModal show={show} onClose={closeHandler}>
				<CModalBody>
					<Error onRetry={walletsQuery.refetch} />
				</CModalBody>
			</CModal>
		);
	}

	const availableWallets = walletsQuery.data.filter((w) => isWalletAllowedToTransfer(w));

	return (
		<CModal show={show} onClose={closeHandler}>
			<CModalHeader closeButton>
				<CModalTitle>{_t('customer.transfer.title')}</CModalTitle>
			</CModalHeader>
			<CModalBody>
				<CFormGroup>
					<CLabel htmlFor="nf-group">{_t('customer.transfer.source-wallet')}</CLabel>
					<CSelect id="nf-type" name="nf-type" onChange={onSourceWalletChange} value={sourceWalletId}>
						{availableWallets.map((w: IWallet) => (
							<option value={w.id} key={`wallet-${w.id}`}>
								{w.id} ({w.isDemo ? _t('global.demo') : _t('global.real')}, {w.type})
							</option>
						))}
					</CSelect>
				</CFormGroup>
				<CFormGroup>
					<CLabel htmlFor="nf-group">{_t('customer.transfer.target-wallet')}</CLabel>
					<CSelect id="nf-type" name="nf-type" onChange={onTargetWalletChange} value={targetWalletId}>
						{availableWallets.map((w: IWallet) => (
							<option value={w.id} key={`wallet-${w.id}`}>
								{w.id} ({w.isDemo ? _t('global.demo') : _t('global.real')}, {w.type})
							</option>
						))}
					</CSelect>
				</CFormGroup>
				<CFormGroup>
					<CLabel htmlFor="nf-amount">{_t('global.amount')}</CLabel>
					<CInputGroup>
						<CInputGroupPrepend>
							<CInputGroupText>$</CInputGroupText>
						</CInputGroupPrepend>
						<CInput
							type="number"
							id="nf-amount"
							name="nf-amount"
							placeholder="12.64"
							value={amount || ''}
							onChange={handleAmountChanged}
						/>
					</CInputGroup>
					{sourceWalletId !== undefined && (
						<CFormText className="help-block">
							{_t('customer.transfer.available-to-transfer')}: <Balance walletId={sourceWalletId} />
						</CFormText>
					)}

					{findError('amount') && <CLabel className="text-danger">{findError('amount')}</CLabel>}
				</CFormGroup>
			</CModalBody>
			<CModalFooter>
				<ButtonWithLoader
					isLoading={transferMutation.isLoading}
					onClick={invokeMutation}
					buttonColor="primary"
					spinnerColor="secondary"
					title={_t('customer.transfer.transfer')}
					className="mr-2"
				/>
				<CButton color="light" variant="outline" onClick={closeHandler} disabled={transferMutation.isLoading}>
					{_t('action.cancel')}
				</CButton>
			</CModalFooter>
		</CModal>
	);
};

export default React.memo(WalletTransferModal);
