import {
	ICreateWallet,
	IInstrument,
	InstrumentCategory,
	IInstrumentSort,
	InstrumentWithoutSort,
	IOrdersQuery,
	IOrderQueryParams,
	IOrder,
	IDowntimesIndexQuery,
	IDowntime,
	IPaymentProviderSort,
	IPaymentProvider,
	IPaymentProviderQuery,
	IUpdateProviderDTO,
	IComment,
	IWalletsQuery,
	IWalletQuery,
	ICustomersQuery,
	ICustomer,
	IAffiliateQueryParams,
	ISalesPersonDetailsQuery,
	ISalesPeopleQuery,
	ICommunication,
	ICustomersWithAgentsQuery,
	ISalesDesk,
	ISalesDeskFull,
	ISalesDesksQuery,
	IListListingQuery,
	ILead,
	ISalesPersonCommunicationQuery,
	ICompetitionsQuery,
	ICompetitionQueryResponse,
	ICompetition,
	ILoyaltyLevelDetailsQuery,
	IUpdateCustomerParams,
	IGroup,
	ICoinsConversionQuery,
	CompetitionType,
	IExchangeRate,
	ICustomerTransactionsQuery,
	ICustomerPositionsQuery,
	ISalesStatus,
	IReferralNode,
	IRiskEvent,
	IWallet,
	IOnboardingStepConfiguration,
	IBulkEditParamsLeads,
	IBulkEditParams,
	IActivityQuery,
	IAuditLogsFiltersDTO,
	IAffiliateInfo,
	IAdapter,
	AdapterType,
	IFIXAdapterConfig,
	ICustomerOnboardingStep,
	IGroupWithExtraData,
	IMarkup,
	ICoinsSettings,
	ICommissionModel,
	IUpdateCommissionModelParams,
	ISalesPerson,
	IPayoutRequest,
	IAffiliateCommissionLedger,
	IPayoutRequestResponse,
	IPayoutRequestFiltersDTO,
	IPushSubscription,
	ITradeCommission,
	IDemoQuoterAdapterConfig,
} from './ImportTypes';
import { stringify } from 'qs';
import { BannerDTO, CreateUpdateBannerDTO } from '../dtos/BannerDTOs';
import { IAnalyticsRecord } from '../pages/riskManagement/AffiliationPerformance';
import { IDateRange } from '../reducers/filtersReducer';
import { IConversion, IConversionCreate, IConversionPath } from '../pages/conversions/types';
import { Buffer } from 'buffer';
import { TransactionType } from '../pages/transactions/types';
import client from '../apiClient';
import IPropChallenge, { ICreatePropChallengeFormData, IPropAccountProgress, IPropChallengeWithProgress, IUpdatePropChallengeFormData } from 'src/pages/propTrading/types';
import { ICreateFundedAccountTypeFormData, IFundedAccountType, IUpdateFundedAccountTypeFormData } from 'src/pages/propTrading/fundedAccountType/types';
import { ICreatePropFunnelFormData, IPropFunnel, IUpdatePropFunnelFormData } from 'src/pages/propTrading/propFunnels/types';

interface ILogin {
	username?: string;
	password?: string;
	refreshToken?: string;
}

export async function login({ username, password, refreshToken }: ILogin) {
	let dataToSend;
	if (refreshToken) {
		dataToSend = {
			refresh_token: refreshToken,
		};
	} else {
		dataToSend = {
			username,
			password,
		};
	}
	const response = await client.post('/api/sessions', dataToSend);
	return response.data;
}

export async function impersonate(customerId: string) {
	const response = await client.post('/api/sessions/impersonate', {
		customerId,
	});

	return response.data;
}

export async function forgotPassword(email: string) {
	const response = await client.post('/api/affiliates/forgot_password', {
		email,
	});

	return response.data;
}

export async function resetPassword(token: string, password: string, passwordConfirmation: string) {
	const response = await client.post('/api/affiliates/reset_password', {
		reset_token: token,
		password,
		password_confirmation: passwordConfirmation,
	});

	return response.data;
}

export async function loadCountries(): Promise<Array<any>> {
	const response = await client.get('/api/countries');
	return response.data.countries;
}

export async function toggleCountryAvailability(countryName: string, toggle: boolean): Promise<Array<any>> {
	const response = await client.put(`/api/admin/countries/${countryName}`, { toggle });
	return response.data.countries;
}

export async function loadCustomer(id: string): Promise<ICustomer> {
	const response = await client.get(`/api/admin/customers/${id}`);
	return {
		...response.data.customer,
		appInstalledAt: response.data.customer.appInstalledAt ? new Date(response.data.customer.appInstalledAt) : null,
		confirmedAt: response.data.customer.confirmedAt ? new Date(response.data.customer.confirmedAt) : null,
	};
}

export async function updateCustomer(customerId: string, attributes: IUpdateCustomerParams) {
	const response = await client.put(`/api/admin/customers/${customerId}`, {
		...attributes,
	});

	return response.data;
}

export async function updateWallet(walletId: string, attributes: any) {
	const response = await client.put(`/api/admin/wallets/${walletId}`, {
		...attributes,
	});

	return response.data;
}

export async function loadProfitLossReport(from: Date, to: Date, limit: number, offset: number, sortBy: string) {
	if (!from || !to) {
		throw new Error('Date range must be set.');
	}

	const start = from.toISOString();
	const end = to.toISOString();

	const qs = stringify({ start, end, limit, offset, sortBy });
	const response = await client.get(`/api/admin/reports/profitloss?${qs}`);
	return response.data;
}

export async function loadWalletBalance(walletId: number) {
	const response = await client.get(`/api/admin/wallets/${walletId}/balance`);
	return response.data;
}

export async function loadCustomerTradingBalance(customerId: string) {
	const response = await client.get(`/api/admin/customers/${customerId}/wallets/primary/balance`);
	return response.data;
}

export async function updateRefkey(refKey: string) {
	const response = await client.post('/api/affiliates/update_refkey', {
		refKey,
	});

	return response.data;
}

export async function loadDashboardStats() {
	const response = await client.get('/api/admin/dashboard');

	return response.data;
}

export async function loadTransactions(
	type: TransactionType | undefined,
	search: string,
	limit: number,
	offset: number,
	sortBy: string,
	filters: any
) {
	const qs = stringify({ type, search, limit, offset, sortBy, filters });
	const response = await client.get(`/api/admin/transactions?${qs}`);

	return response.data;
}

export async function loadTransaction(id: string) {
	const response = await client.get(`/api/admin/transactions/${id}`, {});

	return response.data.transaction;
}

export async function loadCustomerTransactions(
	customerId: string,
	limit: number,
	offset: number
): Promise<ICustomerTransactionsQuery> {
	const response = await client.get(`/api/admin/customers/${customerId}/transactions`, { params: { limit, offset } });

	return response.data;
}

export async function loadDownlineStats(): Promise<Array<IAnalyticsRecord>> {
	const response = await client.get('/api/admin/analytics/downline', {});

	return response.data.stats;
}

export async function loadOrdersQuery(params: IOrderQueryParams): Promise<IOrdersQuery> {
	const response = await client.get(`api/admin/orders?${stringify(params)}`);

	return response.data;
}

export async function loadOrderQuery(id: string): Promise<IOrder> {
	const response = await client.get(`api/admin/orders/${id}`);
	return response.data.order;
}

export async function createTransaction(
	walletId: number,
	type: string,
	amount: number,
	currency: string,
	comment: string | undefined,
	method: string | null,
	addToCredit?: boolean
) {
	const response = await client.post(`/api/admin/wallets/${walletId}/transactions`, {
		type,
		amount,
		currency,
		comment,
		method,
		addToCredit,
	});

	return response.data.transaction;
}

export async function createCustomer(
	name: string,
	mobilenumber: string,
	email: string,
	country: string,
	currency: string
) {
	const response = await client.post('/api/admin/customers', {
		fullname: name,
		mobilenumber,
		email,
		country,
		currency,
	});

	return response.data.success;
}

export async function loadUserSelf(token?: string) {
	const headers: any = {};

	if (token) {
		headers.authorization = `Bearer ${token}`;
	}

	const response = await client.get('/api/users/self', { headers });
	return response.data;
}

export async function updateUserSelf(attributes: any) {
	const response = await client.put('/api/users/self', attributes);
	return response.data;
}

export async function loadWithdrawals() {
	const response = await client.get('api/admin/transactions?type=withdrawal', {});
	return response.data.transactions;
}

export async function requestApproveWithdrawal(withdrawalId: string, comment: string, changedAmount: number) {
	const response = await client.put(`api/admin/transactions/${withdrawalId}/approve`, { comment, changedAmount });
	return response.data.transaction;
}

export async function requestRejectWithdrawal(withdrawalId: string, comment: string) {
	const response = await client.put(`api/admin/transactions/${withdrawalId}/reject`, { comment });
	return response.data.transaction;
}

export async function refundWithdrawal(withdrawalId: string) {
	const response = await client.post(`api/admin/transactions/${withdrawalId}/refund`);
	return response.data.transaction;
}

export async function requestTransfer(transactionId: string, method: string, amount: number, address: string) {
	const response = await client.post(`api/admin/transactions/${transactionId}/transfer`, {
		method,
		amount,
		address,
	});
	return response.data;
}

export async function loadCustomerDocuments(customerId: string) {
	const response = await client.get(`/api/admin/customers/${customerId}/documents`, {});
	return response.data;
}

export async function approveKYCStatus(id: string) {
	const response = await client.post(`/api/admin/customers/${id}/kyc/approve`);
	return response.data;
}

export async function revokeKYCStatus(id: string) {
	const response = await client.post(`/api/admin/customers/${id}/kyc/revoke`);
	return response.data;
}

export async function resetKYCStatus(id: string) {
	const response = await client.post(`/api/admin/customers/${id}/kyc/reset`);
	return response.data;
}

export async function loadCustomers(
	search: string,
	limit: number,
	offset: number,
	introducerId: string | undefined,
	sortBy: string,
	filters: any
): Promise<{ customers: Array<ICustomer>; count: number }> {
	const response = await client.get('/api/admin/customers', {
		params: {
			limit,
			offset,
			search,
			introducerId,
			sortBy,
			...filters,
		},
	});

	return {
		customers: response.data.customers.map((c: any) => ({
			...c,
			appInstalledAt: c.appInstalledAt ? new Date(c.appInstalledAt) : null,
		})),
		count: response.data.count,
	};
}

export async function getCustomerIds(filters: any, search: string): Promise<Array<string>> {
	const response = await client.get('/api/admin/customers/ids', {
		params: {
			...filters,
			search,
		},
	});

	return response.data.customersIds;
}

export async function updateCustomersBulk(data: IBulkEditParams) {
	const response = await client.put(`/api/admin/customers/bulk`, data);
	return response.data;
}

export async function loadCustomerWallets(customerId: string): Promise<Array<IWallet>> {
	const url = `/api/admin/customers/${customerId}/wallets`;
	const response = await client.get(url);
	const { wallets } = response.data;
	return wallets;
}

export async function loadGroups(): Promise<Array<IGroup>> {
	const url = '/api/admin/groups';
	const response = await client.get(url);
	const { groups } = response.data;
	return groups;
}

export async function loadGroupWithExtraData(id: number): Promise<IGroupWithExtraData> {
	const response = await client.get(`/api/admin/groups/${id}`);
	const { group } = response.data;
	return group;
}

export async function loadCustomerSources(): Promise<Array<string>> {
	const response = await client.get('/api/admin/customers/sources');
	return response.data.sources;
}

export async function loadWarehouseAndFixAdapters() {
	const qs = stringify({ onlyTradingAdapters: true });
	const url = `/api/admin/adapters?${qs}`;
	const response = await client.get(url);
	return response.data;
}

export async function saveKYCDocuments(documents: any, userId: string) {
	const bodyFormData = new FormData();
	documents.forEach((doc: any) => {
		bodyFormData.append('file', new Blob([doc.data]), doc.name);
	});

	const response = await client.request({
		method: 'post',
		url: `api/admin/customers/${userId}/documents/upload`,
		data: bodyFormData,
		headers: { 'Content-Type': 'multipart/form-data' },
		params: { userId },
	});
	return response.data;
}

export async function loadHasOpenPositions(accountId: string) {
	const url = `/api/admin/customers/${accountId}/has_open_positions`;
	const response = await client.get(url);
	const { hasOpenPositions } = response.data;
	return hasOpenPositions;
}

export async function confirmEmail(userId: string) {
	const response = await client.put(`/api/admin/customers/${userId}/confirmEmail`);
	return response.data;
}

export async function loadPositions(
	limit: number | null,
	offset: number | null,
	search: string,
	filters: any,
	sortBy?: string
) {
	const qs = stringify({ search, limit, offset, filters, sortBy }, { skipNulls: true });
	const response = await client.get(`/api/admin/positions?${qs}`);
	const { count, pages, positions } = response.data;
	return { positions, count, pages };
}

export async function createPosition(
	accountId: number,
	side: string,
	symbol: string,
	size: number,
	platform: string,
	openPrice: number | undefined,
	takeProfit: number | undefined,
	stopLoss: number | undefined,
	marginRate: number | undefined,
	trailingStopLossPct: number | undefined
) {
	const url = `/api/admin/positions`;

	const response = await client.post(url, {
		accountId,
		side,
		symbol,
		size: Number(size),
		openPrice,
		takeProfit,
		stopLoss,
		platform,
		marginRate,
		trailingStopLossPct
	});

	return response.data;
}

export async function loadPosition(id: string) {
	const url = `/api/admin/positions/${id}`;
	const response = await client.get(url);
	const position = response.data;

	return position;
}

export async function closePosition(id: string) {
	const url = `/api/admin/positions/${id}`;
	const response = await client.delete(url);
	const position = response.data;

	return position;
}

export async function updatePosition(
	id: string,
	stopLoss?: number,
	takeProfit?: number,
	openPrice?: number,
	closePrice?: number,
	commission?: number,
	trailingStopLossPct?: number
) {
	const url = `/api/admin/positions/${id}`;
	const response = await client.patch(url, { stopLoss, takeProfit, openPrice, closePrice, commission, trailingStopLossPct });
	const position = response.data;

	return position;
}

export async function loadOrders(id: string) {
	const url = `/api/admin/positions/${id}/orders`;
	const response = await client.get(url);
	const position = response.data;

	return position;
}

export async function getConversion(id: number): Promise<IConversion> {
	const url = `/api/admin/conversions/${id}`;
	const response = await client.get(url);
	const conversion = response.data;

	return conversion;
}

export async function updateConversion(id: number, path: Array<IConversionPath>): Promise<IConversion> {
	const url = `/api/admin/conversions/${id}`;
	const response = await client.put(url, { path });
	const conversion = response.data;

	return conversion;
}

export async function deleteConversion(id: number): Promise<boolean> {
	const url = `/api/admin/conversions/${id}`;
	const response = await client.delete(url);

	return response.data.success;
}

export async function createConversion({ target, source, path }: IConversionCreate): Promise<IConversion> {
	const url = `/api/admin/conversions`;
	const response = await client.post(url, { path, target, source });
	const conversion = response.data;

	return conversion;
}

export async function getConversions(): Promise<Array<IConversion>> {
	const url = `/api/admin/conversions`;
	const response = await client.get(url);
	const conversions = response.data;

	return conversions;
}

export async function updateGroupWithMarkups(
	id: number,
	leverage: number,
	marginCallLevel: number,
	stopOutLevel: number,
	adapterName: string,
	markups: Array<IMarkup>,
	commissions: Array<ITradeCommission>,
	disableSwapCharges: boolean
): Promise<IGroupWithExtraData> {
	const response = await client.put(`/api/admin/groups/${id}`, {
		leverage,
		markups,
		commissions,
		marginCallLevel,
		stopOutLevel,
		adapterName,
		disableSwapCharges,
	});
	return response.data.group;
}

export async function createWallet(wallet: ICreateWallet) {
	const response = await client.post('/api/admin/wallets', { ...wallet });
	return response.data;
}

export async function createProptradingWallet(customerId: string, propChallengeId: string) {
	const response = await client.post(`/api/admin/prop-challenges/${propChallengeId}/create-wallet`, { customerId });
	return response.data;
}

export async function getOAuthTokensWithCode(
	code: string,
	clientId: string,
	clientSecret: string,
	redirectUri: string,
	grantType: string
) {
	const response = await client.post(
		'/api/oauth/token',
		new URLSearchParams({
			grant_type: grantType,
			code,
			client_id: clientId,
			client_secret: clientSecret,
			redirect_uri: redirectUri,
		}),
		{
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		}
	);
	return response.data;
}

export async function oAuthLogout(refreshToken: string, redirectUri: string): Promise<string> {
	const response = await client.post('/api/oauth/logout', {
		refreshToken,
		redirectUri,
	});
	return response.data.redirectUri;
}

export async function getExchangeRate(amount: number) {
	const response = await client.get('api/admin/exchangeRate', {
		params: {
			method: 'BTC',
			amount,
		},
	});
	return response.data;
}

export async function getWithdrawalMethods(country: string) {
	const response = await client.get('api/admin/transactions/withdrawal_methods', { params: { country } });
	return response.data;
}

export async function createInstrument(instrument: InstrumentWithoutSort) {
	const { icon, ...rest } = instrument;
	const data = {
		...rest,
		filename: icon?.name || undefined,
		file: icon?.data ? Buffer.from(icon?.data).toString('base64') : undefined,
	};

	const response = await client.post(`api/admin/instruments`, data);

	return response.data;
}

export async function updateInstrument(instrument: InstrumentWithoutSort) {
	const { icon, ...rest } = instrument;
	const data = {
		...rest,
		filename: icon?.name || undefined,
		file: icon?.data ? Buffer.from(icon?.data).toString('base64') : undefined,
	};

	const response = await client.put(`api/admin/instruments/${encodeURIComponent(instrument.symbol)}`, data);

	return response.data;
}

export async function getInstruments(category?: InstrumentCategory) {
	const response = await client.get('api/admin/instruments', { params: { category } });
	return response.data.instruments;
}

export async function getInstrument(symbol: string) {
	const response = await client.get(`api/admin/instruments/${encodeURIComponent(symbol)}`);
	return response.data;
}

export async function deleteIstrument(symbol: string) {
	const response = await client.delete(`api/admin/instruments/${encodeURIComponent(symbol)}`);
	return response.data;
}

export async function updateInstrumentOrder(instrumentsSort: IInstrumentSort): Promise<Array<IInstrument>> {
	const response = await client.put('/api/admin/instruments/sort', {
		...instrumentsSort,
	});
	return response.data.instruments;
}

export async function getEmails(search: string) {
	const response = await client.get('/api/admin/emails', { params: { search } });
	return response.data.emails;
}

export async function getEmail(id: string) {
	const response = await client.get(`/api/admin/emails/${id}`);
	return response.data.email;
}

export async function getTriggers(): Promise<Array<string>> {
	const response = await client.get(`/api/admin/emails/triggers`);
	return response.data.triggers;
}

export async function updateEmail(id: string, { subject, mjml, txt, enabled, trigger, title, conditions }: any) {
	const response = await client.put(`/api/admin/emails/${id}`, {
		subject,
		mjml,
		txt,
		enabled,
		trigger,
		title,
		conditions,
	});
	return response.data;
}
export async function createEmail({ id, subject, mjml, txt, enabled, trigger, title, conditions }: any) {
	const response = await client.post(`/api/admin/emails`, {
		id,
		subject,
		mjml,
		txt,
		enabled,
		trigger,
		title,
		conditions,
	});
	return response.data;
}

export async function deleteEmail(id: string): Promise<void> {
	await client.delete(`/api/admin/emails/${id}`);
}

export async function sendTestEmail(id: string) {
	const response = await client.post(`/api/admin/emails/${id}/test_email`);
	return response.data;
}

export async function bookmarkCustomer(customerId: string) {
	const response = await client.post(`api/admin/bookmarks/${customerId}`);
	return response.data;
}

export async function removeBookmark(customerId: string) {
	const response = await client.delete(`api/admin/bookmarks/${customerId}`);
	return response.data;
}

export async function loadBookmarkedCustomers(search: string, limit: number, offset: number, sortBy: string) {
	const response = await client.get('/api/admin/bookmarks', {
		params: {
			limit,
			offset,
			search,
			sortBy,
		},
	});
	const { count, pages } = response.data;
	return {
		customers: response.data.customers.map((c: any) => ({
			...c,
			appInstalledAt: c.appInstalledAt ? new Date(c.appInstalledAt) : null,
		})),
		count,
		pages,
	};
}

export async function loadBalanceReport(
	from: Date,
	to: Date,
	limit: number,
	offset: number,
	sortBy: string,
	excludeZero: boolean
) {
	if (!from || !to) {
		throw new Error('Date range must be set.');
	}

	const start = from.toISOString();
	const end = to.toISOString();

	const qs = stringify({ start, end, limit, offset, sortBy, excludeZero });

	const response = await client.get(`/api/admin/reports/balance?${qs}`);
	return response.data;
}

export async function loadCoinsBalanceReport(limit: number, offset: number, sortBy: string) {
	const qs = stringify({ limit, offset, sortBy });
	const response = await client.get(`/api/admin/reports/coins?${qs}`);
	return response.data.report;
}

export async function createGroup(group: {
	name: string;
	leverage: string;
	currency: string;
	adapterName: string;
	disableSwapCharges: boolean;
}) {
	const response = await client.post('/api/admin/groups', { ...group });
	return response.data;
}

export async function deleteGroup(id: number): Promise<{ success: boolean }> {
	const response = await client.delete(`/api/admin/groups/${id}`);
	return response.data;
}

export async function searchCoins(search: string, limit: number, offset: number, orderBy: any, filters: any) {
	const qs = stringify({ account: search, limit, offset, orderBy, filters });
	const response = await client.get(`/api/admin/coins?${qs}`);
	const { transactions, pages } = response.data;
	return { transactions, pages };
}

export async function fetchCoinsConversion(
	limit: number,
	offset: number,
	filter: string,
	createdAtRange: IDateRange | null
): Promise<ICoinsConversionQuery> {
	const filters = {
		wallet: filter,
		createdAtRange,
	};
	const qs = stringify({ limit, offset, filters });
	const response = await client.get(`api/admin/transactions/coins_exchange?${qs}`);
	return response.data;
}

export async function getUsersPositions(
	customerId: string,
	limit: number,
	offset: number,
	status: string,
	orderBy?: string
): Promise<ICustomerPositionsQuery> {
	const qs = stringify({ limit, offset, status, orderBy });
	const response = await client.get(`/api/admin/positions/accounts/${customerId}/positions?${qs}`);
	return response.data;
}

export async function getUsersOrders(customerId: string): Promise<Array<IOrder>> {
	const response = await client.get(`/api/admin/pending_orders/accounts/${customerId}`);
	return response.data.orders;
}

export async function getFieldsConfigurations() {
	const response = await client.get('api/admin/customer_field_configurations');
	return response.data.customerFieldConfigurations;
}

export async function updateFieldConfiguration(
	field: string,
	editable: boolean,
	required: boolean,
	step: string | null
) {
	const config = { field, editable, required, step };
	const response = await client.put('api/admin/customer_field_configurations', {
		customerFieldConfigurations: [config],
	});
	return response.data;
}

export async function loadBonusConfigs() {
	const url = '/api/admin/bonuses';
	const response = await client.get(url);
	const { bonuses } = response.data;
	return bonuses;
}

export async function assignSalesStatusToCustomer(customerId: string, saleStatusId: number): Promise<any> {
	const response = await client.put(`/api/admin/customers/${customerId}/assignSalesStatus`, {
		saleStatusId,
	});
	return response.data;
}

export async function createBonusConfig(bonus: { id: string; start: Date; end: Date; amount: number }) {
	const response = await client.post('/api/admin/bonuses', { ...bonus });
	return response.data;
}

export async function updateBonusConfig(id: string, bonus: { id: string; start: Date; end: Date; amount: number }) {
	// TODO: encoding this as name is currently used as an id. can be removed when changing to numeric id
	const response = await client.put(`/api/admin/bonuses/${encodeURIComponent(id)}`, { ...bonus });
	return response.data;
}

export async function deleteBonusConfig(id: string) {
	// TODO: encoding this as name is currently used as an id. can be removed when changing to numeric id
	const response = await client.delete(`/api/admin/bonuses/${encodeURIComponent(id)}`);
	return response.data;
}

export async function loadBanners(): Promise<Array<BannerDTO>> {
	const response = await client.get('/api/admin/banners');
	return response.data.banners;
}

export async function createBanner(banner: CreateUpdateBannerDTO): Promise<BannerDTO> {
	const bodyFormData = new FormData();
	bodyFormData.append('name', banner.name);
	bodyFormData.append('startDate', banner.startDate.toISOString());
	bodyFormData.append('endDate', banner.endDate.toISOString());
	if (banner.file) {
		bodyFormData.append('file', new Blob([banner.file.data]), banner.file.name);
	}
	bodyFormData.append('link', banner.link ?? '');
	bodyFormData.append('linkTarget', banner.linkTarget);

	const response = await client.request({
		method: 'post',
		url: '/api/admin/banners',
		data: bodyFormData,
		headers: { 'Content-Type': 'multipart/form-data' },
	});
	return response.data.banner;
}

export async function updateBanner(banner: CreateUpdateBannerDTO): Promise<BannerDTO> {
	const bodyFormData = new FormData();
	bodyFormData.append('id', banner.id!);
	bodyFormData.append('name', banner.name);
	bodyFormData.append('startDate', banner.startDate.toISOString());
	bodyFormData.append('endDate', banner.endDate.toISOString());
	if (banner.file) {
		bodyFormData.append('file', new Blob([banner.file.data]), banner.file.name);
	}
	bodyFormData.append('link', banner.link ?? '');
	bodyFormData.append('linkTarget', banner.linkTarget);

	const response = await client.request({
		method: 'put',
		url: `/api/admin/banners/${banner.id}`,
		data: bodyFormData,
		headers: { 'Content-Type': 'multipart/form-data' },
	});
	return response.data.banner;
}

export async function deleteBanner(id: string): Promise<boolean> {
	const response = await client.delete(`/api/admin/banners/${id}`);
	return response.data.success;
}

export async function getDowntimes(limit: number, offset: number): Promise<IDowntimesIndexQuery> {
	const qs = stringify({ limit, offset });
	const response = await client.get(`/api/admin/downtimes?${qs}`);
	return response.data;
}

export async function deleteDowntime(id: number): Promise<any> {
	const response = await client.delete(`/api/admin/downtimes/${id}`);
	return response.data;
}

export async function createDowntime(
	title: string,
	startDate: string,
	endDate: string,
	symbols: Array<string>
): Promise<IDowntime> {
	const response = await client.post('/api/admin/downtimes', {
		title,
		startDate,
		endDate,
		symbols,
	});
	return response.data;
}

export async function updateDowntime(
	id: number,
	title: string,
	startDate: string,
	endDate: string,
	symbols: Array<string>
): Promise<IDowntime> {
	const response = await client.put(`/api/admin/downtimes/${id}`, {
		title,
		startDate,
		endDate,
		symbols,
	});
	return response.data;
}

export async function getAllSymbols(): Promise<{ symbols: Array<string> }> {
	const response = await client.get('/api/admin/instruments/symbols');
	return response.data;
}

export async function loadPaymentProviders(): Promise<Array<IPaymentProvider>> {
	const response = await client.get('/api/admin/payment_providers');
	return response.data.providers;
}

export async function getPropAccountProgress(id?: number): Promise<IPropAccountProgress> {
	const response = await client.get(`/api/admin/prop-trading/challenges/progress/${id}`);
	return response.data;
}

export async function getPropTradingChallenges(): Promise<Array<IPropChallenge>> {
	const response = await client.get(`/api/admin/prop-trading/challenges`);
	return response.data.propChallengeTypes;
}

export async function getPropTradingChallenge(id: string): Promise<IPropChallengeWithProgress> {
	const response = await client.get(`/api/admin/prop-trading/challenges/${id}`);
	return response.data;
}

export async function deletePropTradingChallenge(id: string): Promise<void> {
	await client.delete(`/api/admin/prop-trading/challenges/${id}`);
}

export async function getFundedAccountTypes(): Promise<Array<IFundedAccountType>> {
	const response = await client.get(`/api/admin/prop-trading/funded-account-type`);
	return response.data.fundedAccountTypes;
}

export async function getFundedAccountType(id: number): Promise<IFundedAccountType> {
	const response = await client.get(`/api/admin/prop-trading/funded-account-type/${id}`);
	return response.data;
}

export async function createFundedAccountType(accountFundedType: ICreateFundedAccountTypeFormData): Promise<IFundedAccountType> {
	const response = await client.post('/api/admin/prop-trading/funded-account-type', { ...accountFundedType });
	return response.data;
}

export async function updateFundedAccountType(accountFundedType: IUpdateFundedAccountTypeFormData, id: number): Promise<IFundedAccountType> {
	const response = await client.put(`/api/admin/prop-trading/funded-account-type/${id}`, { ...accountFundedType });
	return response.data;
}

export async function deleteFundedAccountType(id: number): Promise<void> {
	await client.delete(`/api/admin/prop-trading/funded-account-type/${id}`);
}


export async function getPropFunnels(): Promise<Array<IPropFunnel>> {
	const response = await client.get(`/api/admin/prop-trading/funnels`);
	return response.data.propFunnels;
}

export async function getPropFunnel(id: string): Promise<IPropFunnel> {
	const response = await client.get(`/api/admin/prop-trading/funnels/${id}`);
	return response.data;
}

export async function createPropFunnel(funnel: ICreatePropFunnelFormData): Promise<IPropFunnel> {
	const response = await client.post('/api/admin/prop-trading/funnels', { ...funnel });
	return response.data.funnel;
}

export async function updatePropFunnel(funnel: IUpdatePropFunnelFormData, id: string): Promise<IPropFunnel> {
	const response = await client.put(`/api/admin/prop-trading/funnels/${id}`, { ...funnel });
	return response.data.funnel;
}

export async function deletePropFunnel(id: string): Promise<void> {
	await client.delete(`/api/admin/prop-trading/funnels/${id}`);
}

export async function createPropTradingChallenge(challenge: ICreatePropChallengeFormData) {
	const response = await client.post('/api/admin/prop-trading/challenges', { ...challenge });
	return response.data;
}

export async function updatePropTradingChallenge(challenge: IUpdatePropChallengeFormData, id: string) {
	const response = await client.put(`/api/admin/prop-trading/challenges/${id}`, { ...challenge });
	return response.data;
}

export async function loadPaymentProvider(id: string): Promise<IPaymentProviderQuery> {
	const response = await client.get(`/api/admin/payment_providers/${id}`);
	return response.data.provider;
}

export async function updatePaymentProvidersSort(
	instrumentsSort: IPaymentProviderSort
): Promise<Array<IPaymentProvider>> {
	const paymentProviders = Object.keys(instrumentsSort).map((key) => {
		return { id: key, sort: instrumentsSort[key] };
	});
	const response = await client.put('/api/admin/payment_providers/sort', {
		paymentProviders,
	});
	return response.data.providers;
}

export async function updatePaymentProviderCountries(
	id: string,
	enabledCountryCodes: Array<string>,
	updateDTO: IUpdateProviderDTO,
	keyValues: Array<{ key: string; value: string }>
): Promise<Array<IPaymentProvider>> {
	const response = await client.put(`/api/admin/payment_providers/${id}`, {
		enabledCountryCodes,
		keyValues,
		...updateDTO,
	});
	return response.data.providers;
}

export async function createCustomerComment(customerId: string, comment: string) {
	const response = await client.post(`api/admin/customers/${customerId}/comments`, { comment });
	return response.data;
}

export async function fetchCustomerComments(customerId: string): Promise<Array<IComment>> {
	const response = await client.get(`api/admin/customers/${customerId}/comments`);
	return response.data.comments;
}

export async function fetchWallets(
	limit: number,
	offset: number,
	search: string,
	walletsFilters: any,
	onlyShowWalletsWithOpenPositions?: boolean
): Promise<IWalletsQuery> {
	const qs = stringify({ limit, offset, search, onlyShowWalletsWithOpenPositions, ...walletsFilters });
	const response = await client.get(`api/admin/wallets?${qs}`);
	return response.data;
}

export async function fetchWallet(username: string): Promise<IWalletQuery> {
	const response = await client.get(`api/admin/wallets/${username}`);
	return response.data.wallet;
}

export async function createMassBonus(
	accounts: string,
	name: string,
	amount: number,
	currency: string,
	addToCredit: boolean
) {
	const response = await client.post('/api/admin/mass_bonuses', {
		accounts,
		name,
		amount,
		currency,
		addToCredit,
	});

	return response.data.transaction;
}

export async function fetchAffiliates(params: IAffiliateQueryParams): Promise<ICustomersQuery> {
	const qs = stringify({ ...params });
	const response = await client.get(`api/admin/customers/affiliates?${qs}`);
	return response.data;
}

export async function sendMessageToCustomers(
	customers: string,
	message: string,
	subject: string
): Promise<{ messageRecipients: number }> {
	const response = await client.post('/api/admin/messages', {
		customers,
		message,
		subject,
	});

	return response.data;
}

export async function sendMessageToAllCustomers(
	message: string,
	subject: string
): Promise<{ messageRecipients: number }> {
	const response = await client.post('/api/admin/messages/all', {
		message,
		subject,
	});
	return response.data;
}

export async function fetchSalesPeopleWithTradingVolume(
	search: string | null,
	limit: number,
	offset: number,
	sortBy?: string
): Promise<ISalesPeopleQuery> {
	const qs = stringify({ search, limit, offset, sortBy });
	const response = await client.get(`api/admin/customers/sales_people_with_trading_volume?${qs}`);
	return response.data;
}

export async function fetchSalesPerson(id: string, limit: number, offset: number): Promise<ISalesPersonDetailsQuery> {
	const qs = stringify({ limit, offset });
	const response = await client.get(`api/admin/customers/sales_people/${id}?${qs}`);
	return response.data;
}

export async function loadAssignableCustomers(
	search: string,
	limit: number,
	offset: number,
	sortBy: string,
	roles: Array<string> | null
) {
	const response = await client.get('/api/admin/customers', {
		params: {
			limit,
			offset,
			search,
			sortBy,
			roles,
		},
	});
	const { count, pages } = response.data;
	return {
		customers: response.data.customers.map((c: any) => ({
			...c,
			appInstalledAt: c.appInstalledAt ? new Date(c.appInstalledAt) : null,
		})),
		count,
		pages,
	};
}

export async function canUserBeCalled(customerId: string): Promise<boolean> {
	const response = await client.get(`api/admin/customers/${customerId}/can_be_called`);
	return response.data.canBeCalled;
}

export async function getCallProviderRequestURL(provider: string): Promise<string> {
	const response = await client.get(`/api/admin/calls/request_url?provider=${provider}`);
	return response.data.url;
}

export async function getVoipProvider(): Promise<string> {
	const response = await client.get(`/api/admin/calls/voip_provider`);
	return response.data.provider;
}

export async function getCallProviderApiKey(provider: string): Promise<string> {
	const response = await client.get(`/api/admin/calls/api_key?provider=${provider}`);
	return response.data.apiKey;
}

export async function configureCallAgent(customerId: string, agentId: string, provider: string) {
	const response = await client.put(`/api/admin/calls/configure_call_agent/${customerId}`, {
		agentId,
		provider,
	});
	return response.data;
}

export async function callCustomer(customerId: string) {
	const response = await client.post(`/api/admin/calls/${customerId}`);
	return response.data;
}

export async function fetchCustomerCommunication(
	customerId: string,
	limit: number,
	offset: number
): Promise<{ communications: Array<ICommunication>; count: number }> {
	const qs = stringify({ limit, offset });
	const response = await client.get(`/api/admin/communications/${customerId}?${qs}`);
	return response.data;
}

export async function updateVariable(variableValue: string, provider: string): Promise<string> {
	const response = await client.put('/api/admin/calls/update_variable', {
		variableValue,
		provider,
	});
	return response.data;
}

export async function updateApiKey(apiKey: string, provider: string): Promise<string> {
	const response = await client.put('/api/admin/calls/update_api_key', {
		variableValue: apiKey,
		provider,
	});
	return response.data;
}

export async function updateVoipProvider(provider: string | null): Promise<string> {
	const response = await client.put('/api/admin/calls/update_voip_provider', {
		provider,
	});
	return response.data;
}

export async function getCustomersWithAgents(
	limit: number,
	offset: number,
	provider: string
): Promise<ICustomersWithAgentsQuery> {
	const response = await client.get(`/api/admin/calls/customer_agents?`, {
		params: {
			limit,
			offset,
			provider,
		},
	});
	const { customers, pages } = response.data;
	return { customers, pages };
}

export async function listSalesDesks(limit: number, offset: number): Promise<ISalesDesksQuery> {
	const qs = stringify({ limit, offset });
	const response = await client.get(`/api/admin/salesDesk/list?${qs}`);
	return response.data;
}

export async function createSalesDesk(name: string): Promise<ISalesDesk> {
	const response = await client.post('/api/admin/salesDesk/create', {
		name,
	});
	return response.data;
}

export async function updateSalesDesk(id: string, name: string): Promise<ISalesDesk> {
	const response = await client.put(`/api/admin/salesDesk/${id}`, {
		name,
	});
	return response.data;
}

export async function deleteSalesDesk(id: string): Promise<ISalesDesk> {
	const response = await client.delete(`/api/admin/salesDesk/${id}`);
	return response.data;
}

export async function fetchSalesDesk(id: string): Promise<ISalesDeskFull> {
	const response = await client.get(`/api/admin/salesDesk/${id}`);
	return response.data;
}

export async function sendEmailToCustomer(customerId: string, subject: string, body: string): Promise<any> {
	const response = await client.post(`api/admin/customers/${customerId}/sendEmail`, { subject, body });
	return response.data;
}

export async function listLeads(
	limit: number,
	offset: number,
	orderBy: string,
	filters: any,
	search?: string
): Promise<IListListingQuery> {
	const qs = stringify({ limit, offset, orderBy, filters, search });
	const response = await client.get(`/api/admin/leads?${qs}`);
	return response.data;
}

export async function getLead(id: string): Promise<ILead> {
	const response = await client.get(`/api/admin/leads/${id}`);
	return response.data;
}

export async function updateLead(leadId: string, salesStatusId: number): Promise<ILead> {
	const response = await client.put(`/api/admin/leads/${leadId}`, {
		salesStatusId,
	});
	return response.data;
}

export async function updateLeadSalesPerson(leadId: string, salesPersonId: string): Promise<ILead> {
	const response = await client.put(`/api/admin/leads/${leadId}/assign_sales_person`, {
		salesPersonId,
	});
	return response.data;
}

export async function createLeadComment(id: string, comment: string): Promise<any> {
	const response = await client.post(`/api/admin/leads/${id}/comment`, {
		comment,
	});
	return response.data;
}

export async function listLeadComments(id: string): Promise<Array<IComment>> {
	const response = await client.get(`/api/admin/leads/${id}/comments`);
	return response.data;
}

export async function claimLead(id: string): Promise<ILead> {
	const response = await client.post(`/api/admin/leads/${id}/claim`);
	return response.data;
}

export async function sendEmailToLead(id: string, subject: string, body: string): Promise<any> {
	const response = await client.post(`/api/admin/leads/${id}/email`, {
		subject,
		body,
	});
	return response.data;
}

export async function callLead(leadId: string) {
	const response = await client.post(`/api/admin/calls/${leadId}/lead`);
	return response.data;
}

export async function fetchLeadCommunications(leadId: string): Promise<Array<ICommunication>> {
	const response = await client.get(`/api/admin/leads/${leadId}/communications`);
	return response.data.communications;
}

export async function saveLeadsInCSV(documents: any) {
	const bodyFormData = new FormData();
	documents.forEach((doc: any) => {
		bodyFormData.append('file', new Blob([doc.data]), doc.name);
	});

	const response = await client.request({
		method: 'post',
		url: 'api/admin/leads/upload',
		data: bodyFormData,
		headers: { 'Content-Type': 'multipart/form-data' },
	});
	return response.data;
}

export async function fetchSalesCommunications(
	salesPersonId: string,
	limit: number,
	offset: number
): Promise<ISalesPersonCommunicationQuery> {
	const qs = stringify({ limit, offset });
	const response = await client.get(`/api/admin/customers/sales_people/${salesPersonId}/communications?${qs}`);
	const { communications, count } = response.data;
	return { communications, count };
}

export async function fetchSources(): Promise<Array<string>> {
	const response = await client.get('/api/admin/leads/sources');
	return response.data;
}

export async function fetchPaymentProvidersForFilters(type: string): Promise<Array<string>> {
	const qs = stringify({ type });
	const response = await client.get(`/api/admin/payment_providers/filters?${qs}`);
	return response.data.methods;
}

export async function loadCompetitions(
	search: string,
	limit: number,
	offset: number,
	sortBy: string
): Promise<ICompetitionsQuery> {
	const response = await client.get('/api/competitions', { params: { search, limit, offset, sortBy } });
	const { competitions, pages } = response.data;
	const mappedCompetitions = competitions.map((c: any) => ({
		...c,
		start: new Date(c.start),
		end: new Date(c.end),
	}));

	return {
		competitions: mappedCompetitions,
		pages,
	};
}

export async function loadCompetition(id: string): Promise<ICompetitionQueryResponse> {
	const response = await client.get(`/api/competitions/${id}`);

	return {
		...response.data,
		competition: {
			...response.data.competition,
			start: new Date(response.data.competition.start),
			end: new Date(response.data.competition.end),
		},
	};
}

export async function createCompetition(
	name: string,
	from: string,
	to: string,
	prizes: Array<number | null>,
	type: CompetitionType | null,
	tcLink: string | null,
	promoted: boolean
): Promise<ICompetition> {
	const response = await client.post('/api/competitions', {
		description: name,
		start: new Date(from),
		end: new Date(to),
		firstPrizeInUSD: prizes[0],
		secondPrizeInUSD: prizes[1],
		thirdPrizeInUSD: prizes[2],
		type,
		tcLink,
		promoted,
	});

	return response.data.competition;
}

export async function updateCompetition(
	id: string,
	name: string,
	from: string,
	to: string,
	prizes: Array<number | null>,
	tcLink: string | null,
	promoted: boolean
): Promise<ICompetition> {
	const response = await client.put(`/api/competitions/${id}`, {
		description: name,
		start: new Date(from),
		end: new Date(to),
		firstPrizeInUSD: prizes[0],
		secondPrizeInUSD: prizes[1],
		thirdPrizeInUSD: prizes[2],
		tcLink,
		promoted,
	});

	return response.data.competition;
}

export async function deleteCompetition(id: string): Promise<boolean> {
	const response = await client.delete(`/api/competitions/${id}`);

	return response.data.success;
}

export async function fetchCustomerLoyatlyLevelDetails(id: string): Promise<ILoyaltyLevelDetailsQuery> {
	const response = await client.get(`/api/customers/${id}/loyalty`);
	return response.data;
}

export async function fetchMaxRisk(): Promise<{ maxRisks: Array<string>; defaultValue: string }> {
	const response = await client.get('/api/admin/max_risks');
	return response.data;
}

export async function updateMaxRiskConfig(
	value: Array<string>,
	defaultValue: string
): Promise<{ maxRisks: Array<string> }> {
	const response = await client.put(`/api/admin/max_risks`, {
		value,
		defaultValue,
	});
	return response.data;
}

export async function fetchPositionDurations(): Promise<{ positionDurations: Array<string>; defaultValue: string }> {
	const response = await client.get('/api/admin/position_durations');
	return response.data;
}

export async function updatePositionDurations(
	value: Array<string>,
	defaultValue: string
): Promise<{ positionDurations: Array<string> }> {
	const response = await client.put(`/api/admin/position_durations`, {
		value,
		defaultValue,
	});
	return response.data;
}

export async function createExchangeRate(
	fromCurrency: string,
	toCurrency: string,
	source: string,
	rate: number | null
): Promise<IExchangeRate> {
	const response = await client.post(`/api/admin/exchangeRate`, {
		fromCurrency,
		toCurrency,
		source,
		rate,
	});
	return response.data;
}

export async function updateExchangeRate(
	id: string,
	fromCurrency: string,
	toCurrency: string,
	source: string,
	rate: number | null
): Promise<IExchangeRate> {
	const response = await client.put(`/api/admin/exchangeRate/${id}`, {
		fromCurrency,
		toCurrency,
		source,
		rate,
	});
	return response.data;
}

export async function getExchangeRates(): Promise<Array<IExchangeRate>> {
	const response = await client.get(`/api/admin/exchangeRate/rates`);
	return response.data;
}

export async function createSalesStatus(status: string, color: string): Promise<ISalesStatus> {
	const response = await client.post(`/api/admin/sales_status`, {
		status,
		color,
	});
	return response.data;
}

export async function updateSalesStatus(id: number, status: string, color: string): Promise<ISalesStatus> {
	const response = await client.put(`/api/admin/sales_status/${id}`, {
		status,
		color,
	});
	return response.data;
}

export async function listSalesPeople(): Promise<Array<ICustomer>> {
	const response = await client.get(`/api/admin/customers/sales_people`);
	return response.data;
}

export async function getSalesStatuses(): Promise<Array<ISalesStatus>> {
	const response = await client.get(`/api/admin/sales_status`);
	return response.data.statuses;
}

export async function listSalesPeopleForLeads(): Promise<Array<ISalesPerson>> {
	const response = await client.get('/api/admin/leads/sales_people');
	return response.data.salesPeople;
}

export async function getSalesStatus(name: string): Promise<ISalesStatus> {
	const response = await client.get(`/api/admin/sales_status/${name}`);
	return response.data.status;
}

export async function getReferralTree(id: string): Promise<IReferralNode> {
	const response = await client.get(`/api/admin/customers/referral_tree/${encodeURIComponent(id)}`);
	return response.data;
}

export async function fetchCustomerRiskEvents(
	customerId: string,
	limit: number,
	offset: number
): Promise<{ riskEvents: Array<IRiskEvent>; count: number }> {
	const qs = stringify({ limit, offset });
	const response = await client.get(`/api/admin/customers/${customerId}/risk_events?${qs}`);
	return response.data;
}

export async function createWalletTransfer(
	sourceWalletId: number,
	targetWalletId: number,
	amount: number
): Promise<boolean> {
	const response = await client.post(`/api/admin/wallets/transfer`, {
		sourceWalletId,
		targetWalletId,
		amount,
	});
	return response.data;
}

export async function getOnboardingStepConfigurations(): Promise<Array<IOnboardingStepConfiguration>> {
	const response = await client.get('api/admin/onboarding_step_configuration');
	return response.data.configurations;
}

export async function getOnboardingStepsStages(): Promise<Array<string>> {
	const response = await client.get('api/admin/onboarding_step_configuration/stages');
	return response.data.stages;
}

export async function updateOnboardingStepStage(step: string, stage: string | null): Promise<Array<string>> {
	const response = await client.put(`api/admin/onboarding_step_configuration/${step}`, {
		stage,
	});
	return response.data.stages;
}

export async function getSetting(key: string): Promise<string> {
	const response = await client.get(`api/admin/settings/keys/${key}`);
	return response.data.value;
}

export async function setSetting(key: string, value: string | boolean | null): Promise<string | boolean | null> {
	const response = await client.put(`api/admin/settings/keys/${key}`, {
		val: value,
	});
	return response.data;
}

export async function updateRecaptchaSettings(siteKey: string, secret: string, score: string, url: string) {
	const response = await client.put(`api/admin/settings/recaptcha`, {
		siteKey,
		secret,
		score,
		url
	});
	return response.data;
}
export async function updateCoperato(url: string): Promise<boolean> {
	const response = await client.put(`api/admin/settings/voip/coperato`, {
		url,
	});
	return response.data;
}

export async function updateVoiso(url: string, apiKey: string): Promise<boolean> {
	const response = await client.put(`api/admin/settings/voip/voiso`, {
		url,
		apiKey,
	});
	return response.data;
}

export async function updateSendgrid(sender: string, staffEmail: string, apiKey: string): Promise<boolean> {
	const response = await client.put(`api/admin/settings/email/sendgrid`, {
		sender,
		staffEmail,
		apiKey,
	});
	return response.data;
}

export async function updateMailgun(
	sender: string,
	apiKey: string,
	username: string,
	domain: string,
	staffEmail: string
): Promise<boolean> {
	const response = await client.put(`api/admin/settings/email/mailgun`, {
		sender,
		apiKey,
		username,
		domain,
		staffEmail,
	});
	return response.data;
}

export async function updateSumsub(
	apiUrl: string,
	secretKey: string,
	levelName: string,
	webhookSecretKey: string,
	appToken: string
): Promise<void> {
	await client.put(`api/admin/settings/sumsub`, {
		apiUrl,
		secretKey,
		levelName,
		appToken,
		webhookSecretKey,
	});
}

export async function updateSmtpProvider(
	sender: string,
	host: string,
	username: string,
	port: string,
	password: string,
	secure: boolean,
	staffEmail: string
): Promise<boolean> {
	const response = await client.put(`api/admin/settings/email/smtp_provider`, {
		sender,
		host,
		username,
		port,
		password,
		secure,
		staffEmail,
	});
	return response.data;
}

export async function updateBrevo(sender: string, apiKey: string, staffEmail: string): Promise<boolean> {
	const response = await client.put(`api/admin/settings/email/brevo`, {
		sender,
		apiKey,
		staffEmail,
	});
	return response.data;
}

export async function updateOrder(
	id: string,
	limitPrice: string | undefined | null,
	stopPrice: string | undefined | null,
	takeProfit: string | undefined | null,
	stopLoss: string | undefined | null,
	trailingStopLossPct: string | undefined | null
): Promise<IOrder> {
	const response = await client.patch(`api/admin/orders/${id}`, {
		limitPrice,
		stopPrice,
		takeProfit,
		stopLoss,
		trailingStopLossPct
	});

	return response.data;
}

export async function cancelOrder(id: string): Promise<boolean> {
	const response = await client.delete(`api/admin/orders/${id}`);
	return response.data.success;
}

export async function updateWalletConfig(group: string) {
	const response = await client.put(`api/admin/settings/wallet_configuration`, {
		group,
	});

	return response.data;
}

export async function enableWallet(walletId: number) {
	const response = await client.put(`/api/admin/wallets/${walletId}/enable`);
	return response.data;
}

export async function disableWallet(walletId: number) {
	const response = await client.put(`/api/admin/wallets/${walletId}/disable`);
	return response.data;
}

export async function fetchActivites(
	limit: number,
	offset: number,
	filter: string | null,
	filters: IAuditLogsFiltersDTO
): Promise<IActivityQuery> {
	const qs = stringify({ limit, offset, filter, filters });
	const response = await client.get(`/api/admin/activity?${qs}`);
	return response.data;
}

export async function fetchAffiliateCustomer(id: string): Promise<IAffiliateInfo> {
	const response = await client.get(`api/admin/customers/affiliates/${id}`);
	return response.data;
}

export async function fetchAdapters(): Promise<Array<IAdapter>> {
	const response = await client.get(`api/admin/adapters`);
	return response.data;
}

export async function fetchAdapter(name: string): Promise<IAdapter> {
	const response = await client.get(`api/admin/adapters/${name}`);
	return response.data.adapter;
}

export async function updateAdapter(
	name: string,
	enabled: boolean,
	type: AdapterType,
	configuration: IFIXAdapterConfig | IDemoQuoterAdapterConfig | {} | undefined
): Promise<any> {
	const response = await client.put(`api/admin/adapters/${name}`, {
		enabled,
		type,
		configuration,
	});
	return response.data;
}

export async function createAdapter(
	name: string,
	enabled: boolean,
	type: AdapterType,
	configuration: IFIXAdapterConfig | IDemoQuoterAdapterConfig | {} | undefined
): Promise<any> {
	const response = await client.post(`api/admin/adapters`, {
		name,
		enabled,
		type,
		configuration,
	});
	return response.data;
}

export async function deleteAdapter(name: string): Promise<any> {
	const response = await client.delete(`api/admin/adapters/${name}`);
	return response.data;
}

export async function getCustomerOnboardingSteps(customerId: string): Promise<Array<ICustomerOnboardingStep>> {
	const response = await client.get(`api/admin/customers/${customerId}/all_onboarding_steps`);
	return response.data;
}

export async function updateCustomerStep(customerId: string, step: string): Promise<string> {
	const response = await client.put(`api/admin/customers/${customerId}/update_onboarding_step`, {
		step,
	});
	return response.data.success;
}

export async function updateLeadsBulk(data: IBulkEditParamsLeads) {
	const response = await client.put(`/api/admin/leads/bulk`, data);
	return response.data;
}

export async function coinsSettings(): Promise<ICoinsSettings> {
	const response = await client.get('api/admin/coins/settings');
	return response.data;
}

export async function updateCoinsSettings(coinsEnabled: boolean, dollarsPerCoin: number): Promise<boolean> {
	const response = await client.put('api/admin/coins/settings', {
		coinsEnabled,
		dollarsPerCoin,
	});
	return response.data.success;
}

export async function createCommissionModel(model: Omit<ICommissionModel, 'subCommissionModel' | 'default'>): Promise<boolean> {
	const response = await client.post(`/api/admin/commissions`, model);
	return response.data.success;
}

export async function updateCommissionModel(updateObject: IUpdateCommissionModelParams): Promise<boolean> {
	const response = await client.put(`/api/admin/commissions/${updateObject.id}`, updateObject.commissionModel);
	return response.data.success;
}

export async function deleteCommissionModel(id: string): Promise<boolean> {
	const response = await client.delete(`/api/admin/commissions/${id}`);
	return response.data.success;
}

export async function getCommissionModels(): Promise<Array<ICommissionModel>> {
	const response = await client.get(`/api/admin/commissions`);
	return response.data.commissionModels;
}

export async function getCommissionModel(id: string): Promise<ICommissionModel> {
	const response = await client.get(`/api/admin/commissions/${id}`);
	return response.data.commissionModel;
}

export async function getPayoutRequests(
	limit: number,
	offset: number,
	order: 'ASC' | 'DESC',
	filters: IPayoutRequestFiltersDTO
): Promise<{ payoutRequests: Array<IPayoutRequest>; count: number }> {
	const qs = stringify({ limit, offset, order, statuses: filters.statuses });
	const response = await client.get(`/api/admin/commissions/payouts?${qs}`);
	return response.data;
}

export async function getPayoutRequest(id: string, limit: number, offset: number): Promise<IPayoutRequestResponse> {
	const qs = stringify({ limit, offset });
	const response = await client.get(`/api/admin/commissions/payouts/${id}?${qs}`);
	return response.data;
}

export async function acceptPayoutRequest(id: string): Promise<IPayoutRequest> {
	const response = await client.patch(`/api/admin/commissions/payouts/${id}/approve`);
	return response.data.payoutRequest;
}

export async function rejectPayoutRequest(id: string, rejectReason: string | null): Promise<IPayoutRequest> {
	const response = await client.patch(`/api/admin/commissions/payouts/${id}/reject`, {
		rejectReason,
	});
	return response.data.payoutRequest;
}

export async function updateAffiliateCommissionLedger(
	ledgerId: string,
	correctedCommissionAmount?: number,
	adminComments?: string
): Promise<IAffiliateCommissionLedger> {
	const response = await client.patch(`/api/admin/commissions/ledger/${ledgerId}`, {
		correctedCommissionAmount,
		adminComments,
	});
	return response.data.commissionLedger;
}

export async function getAffiliateCommissionLedger(ledgerId: string): Promise<IAffiliateCommissionLedger> {
	const response = await client.get(`/api/admin/commissions/ledger/${ledgerId}`);
	return response.data.commissionLedger;
}

export async function getLedgers(
	limit: number,
	offset: number,
	orderBy: string
): Promise<{ records: Array<IAffiliateCommissionLedger>; count: number }> {
	const qs = stringify({ limit, offset, orderBy });
	const response = await client.get(`/api/admin/commissions/ledgers?${qs}`);
	return response.data;
}

export async function getVapidKey(): Promise<string> {
	const response = await client.get('/api/push_notifications/vapid_key');
	return response.data.publicVapidKey;
}

export async function deleteSubscription(endpoint: string): Promise<{ success: boolean }> {
	const qs = stringify({ topic: 'admin', endpoint });
	const response = await client.delete(`/api/push_notifications/subscriptions?${qs}`);
	return response.data;
}

export async function getSubscription(endpoint: string): Promise<IPushSubscription | null> {
	const qs = stringify({ topic: 'admin', endpoint });
	const response = await client.get(`/api/push_notifications/subscriptions?${qs}`);
	return response.data;
}

export async function pushSubscribe(subscription: PushSubscription): Promise<IPushSubscription> {
	const response = await client.post('/api/push_notifications/subscriptions', { subscription, topic: 'admin' });
	return response.data;
}