import { AnyAction } from 'redux';
import {
	CLEAR_CUSTOMERS_FILTERS,
	SET_CUSTOMERS_FILTERS,
	SET_DEPOSIT_FILTERS,
	CLEAR_DEPOSIT_FILTERS,
	SET_WITHDRAWAL_FILTERS,
	CLEAR_WITHDRAWAL_FILTERS,
	SET_POSITIONS_FILTERS,
	SET_COINS_FILTERS,
	CLEAR_COINS_FILTERS,
	CLEAR_POSITIONS_FILTERS,
	SET_OPEN_POSITIONS_FILTERS,
	CLEAR_OPEN_POSITIONS_FILTERS,
	SET_ORDERS_FILTERS,
	CLEAR_ORDER_FILTERS,
	SET_WALLETS_FILTERS,
	CLEAR_WALLETS_FILTERS,
	SET_AFFILIATE_FILTERS,
	CLEAR_AFFILIATE_FILTERS,
	SET_LEADS_FILTERS,
	CLEAR_LEADS_FILTERS,
	SET_COINS_CONVERSION_FILTERS,
	CLEAR_COINS_CONVERSION_FILTERS,
	SET_AUDIT_LOGS_FILTERS,
	CLEAR_AUDIT_LOGS_FILTERS,
	SET_RISK_MANAGEMENT_FILTERS,
	CLEAR_RISK_MANAGEMENT_FILTERS,
	SET_PAYOUT_REQUEST_FILTERS,
	CLEAR_PAYOUT_REQUEST_FILTERS,
	CLEAR_TRANSACTIONS_FILTERS,
	SET_TRANSACTIONS_FILTERS,
} from '../actions';
import { ISelectOption } from '../components/CustomSelect';
import { OrderType, StatusType } from '../pages/orders/types';
import { ISelectOption as ToggleSelectOption } from '../components/ToggleSelect';
import { TransactionType } from '../pages/transactions/types';
import { TradingType } from '../services/ImportTypes';

export interface IDateRange {
	startDate: Date | null;
	endDate: Date | null;
}

export interface ICustomersFilters {
	country?: ISelectOption;
	group?: ISelectOption;
	lastActivityRange?: IDateRange;
	registrationRange?: IDateRange;
	selectedStatuses: Array<ISelectOption>;
	selectedKycStatuses: Array<ISelectOption>;
	sources: Array<ToggleSelectOption>;
	depositCount?: ISelectOption;
	salesStatus?: ISelectOption;
	positions?: ISelectOption;
	activity?: ISelectOption;
	riskStatus?: ISelectOption;
	conversionAgents: Array<ToggleSelectOption>;
	retentionAgents: Array<ToggleSelectOption>;
}

export interface ITransactionFilters {
	selectedStatuses: Array<ISelectOption>;
	selectedProviders: Array<ISelectOption>;
	createdAtRange?: IDateRange;
	amountFrom?: string;
	amountTo?: string;
	type?: TransactionType;
}

export interface IPositionsFilters {
	types: Array<ISelectOption>;
	symbols: Array<ISelectOption>;
	statuses: Array<ISelectOption>;
	openedAt?: IDateRange;
	accountId?: string | null;
}

export interface IOpenPositionsFilters {
	types: Array<ISelectOption>;
	symbols: Array<ISelectOption>;
	openedAt?: IDateRange;
	accountId?: string | null;
}

export interface IWalletsFilters {
	currency: Array<ISelectOption>;
	groups: Array<ISelectOption>;
	type: ISelectOption | null;
	leverageFrom?: string;
	leverageTo?: string;
	tradingType: ISelectOption | null;
	propFunnel: ISelectOption | null;
	mode: ISelectOption | null;
}

export interface ICoinsFilters {
	createdAt?: IDateRange;
}

export interface ICoinsConversionFilters {
	createdAt?: IDateRange;
}

export interface IOrdersFilters {
	statuses?: Array<StatusType>;
	orderTypes?: Array<OrderType>;
	accountId?: string | null;
	symbol?: Array<string>;
}

export interface IAffiliateFilters {
	statuses: Array<ISelectOption>;
	country: ISelectOption | null;
}

export interface ILeadsFilters {
	statuses: Array<ISelectOption>;
	salesPeople: Array<ToggleSelectOption>;
	onlyAssignedToMe: boolean;
	sources: Array<ToggleSelectOption>;
	country: { value: string; label: string } | null;
	modifiedBefore: Date | null;
	modifiedAfter: Date | null;
}

export interface IAuditLogsFilters {
	selectedActions: Array<ISelectOption>;
	selectedTypes: Array<ISelectOption>;
	createdAt?: IDateRange;
	actedAs: ISelectOption | undefined;
}

export interface IRiskManagement {
	group?: ISelectOption;
	isDemo?: ISelectOption;
}

export interface IPayoutRequestFilters {
	statuses: Array<ISelectOption>;
}

export interface IFiltersState {
	customersFilters: ICustomersFilters;
	depositsFilters: ITransactionFilters;
	withdrawalFilters: ITransactionFilters;
	positionsFilters: IPositionsFilters;
	openPositionsFilters: IOpenPositionsFilters;
	coinsFilters: ICoinsFilters;
	coinsConversionsFilters: ICoinsConversionFilters;
	ordersFilters: IOrdersFilters;
	walletsFilters: IWalletsFilters;
	affiliateFilters: IAffiliateFilters;
	leadsFilters: ILeadsFilters;
	auditLogsFilters: IAuditLogsFilters;
	riskManagementFilters: IRiskManagement;
	payoutRequestFilters: IPayoutRequestFilters;
	transactionsFilters: ITransactionFilters;
}

const initialState = {
	customersFilters: {
		selectedStatuses: [],
		selectedKycStatuses: [],
		sources: [],
		retentionAgents: [],
		conversionAgents: [],
	},
	depositsFilters: {
		selectedStatuses: [],
		selectedProviders: [],
		type: TransactionType.Deposit,
	},
	withdrawalFilters: {
		selectedStatuses: [],
		selectedProviders: [],
		type: TransactionType.Withdrawal,
	},
	positionsFilters: {
		types: [],
		symbols: [],
		statuses: [],
	},
	openPositionsFilters: {
		types: [],
		symbols: [],
	},
	coinsFilters: {},
	coinsConversionsFilters: {},
	ordersFilters: {},
	walletsFilters: {
		currency: [],
		type: null,
		routingAdapters: [],
		groups: [],
		tradingType: null,
		propFunnel: null,
		mode: null,
	},
	affiliateFilters: {
		statuses: [],
		country: null,
	},
	leadsFilters: {
		statuses: [],
		salesPeople: [],
		onlyAssignedToMe: false,
		sources: [],
		country: null,
		modifiedBefore: null,
		modifiedAfter: null,
	},
	auditLogsFilters: {
		selectedActions: [],
		selectedTypes: [],
		actedAs: undefined,
	},
	riskManagementFilters: {},
	payoutRequestFilters: {
		statuses: [],
	},
	transactionsFilters: {
		selectedStatuses: [],
		selectedProviders: [],
	},
};

function filtersReducer(state: IFiltersState | undefined, action: AnyAction): IFiltersState {
	if (typeof state === 'undefined') {
		return initialState;
	}

	switch (action.type) {
		case SET_CUSTOMERS_FILTERS:
			return {
				...state,
				customersFilters: {
					...state.customersFilters,
					...action.payload.filters,
				},
			};
		case CLEAR_CUSTOMERS_FILTERS:
			return {
				...state,
				customersFilters: {
					...initialState.customersFilters,
					sources: state.customersFilters.sources.map((s) => {
						return {
							...s,
							enabled: false,
						};
					}),
					conversionAgents: state.customersFilters.conversionAgents.map((option) => {
						return {
							...option,
							enabled: false,
						};
					}),
					retentionAgents: state.customersFilters.retentionAgents.map((option) => {
						return {
							...option,
							enabled: false,
						};
					}),
				},
			};
		case SET_DEPOSIT_FILTERS:
			return {
				...state,
				depositsFilters: {
					...state.depositsFilters,
					...action.payload.filters,
				},
			};
		case CLEAR_DEPOSIT_FILTERS:
			return {
				...state,
				depositsFilters: {
					...initialState.depositsFilters,
					amountFrom: '',
					amountTo: '',
				},
			};
		case SET_WITHDRAWAL_FILTERS:
			return {
				...state,
				withdrawalFilters: {
					...state.withdrawalFilters,
					...action.payload.filters,
				},
			};
		case CLEAR_WITHDRAWAL_FILTERS:
			return {
				...state,
				withdrawalFilters: {
					...initialState.withdrawalFilters,
					amountFrom: '',
					amountTo: '',
				},
			};
		case SET_TRANSACTIONS_FILTERS:
			return {
				...state,
				transactionsFilters: {
					...state.transactionsFilters,
					...action.payload.filters,
				},
			};
		case CLEAR_TRANSACTIONS_FILTERS:
			return {
				...state,
				transactionsFilters: {
					...initialState.transactionsFilters,
					amountFrom: '',
					amountTo: '',
				},
			};
		case SET_POSITIONS_FILTERS:
			return {
				...state,
				positionsFilters: {
					...state.positionsFilters,
					...action.payload.filters,
				},
			};
		case CLEAR_POSITIONS_FILTERS:
			return {
				...state,
				positionsFilters: {
					...initialState.positionsFilters,
				},
			};
		case SET_OPEN_POSITIONS_FILTERS:
			return {
				...state,
				openPositionsFilters: {
					...state.openPositionsFilters,
					...action.payload.filters,
				},
			};
		case CLEAR_OPEN_POSITIONS_FILTERS:
			return {
				...state,
				openPositionsFilters: {
					...initialState.openPositionsFilters,
				},
			};
		case SET_COINS_FILTERS:
			return {
				...state,
				coinsFilters: {
					...state.coinsFilters,
					...action.payload.filters,
				},
			};
		case CLEAR_COINS_FILTERS:
			return {
				...state,
				coinsFilters: {
					...initialState.coinsFilters,
				},
			};
		case SET_COINS_CONVERSION_FILTERS:
			return {
				...state,
				coinsConversionsFilters: {
					...state.coinsConversionsFilters,
					...action.payload.filters,
				},
			};
		case CLEAR_COINS_CONVERSION_FILTERS:
			return {
				...state,
				coinsConversionsFilters: {
					...initialState.coinsConversionsFilters,
				},
			};
		case SET_ORDERS_FILTERS:
			return {
				...state,
				ordersFilters: {
					...state.ordersFilters,
					...action.payload.filters,
				},
			};
		case CLEAR_ORDER_FILTERS:
			return {
				...state,
				ordersFilters: {
					...initialState.ordersFilters,
				},
			};
		case SET_WALLETS_FILTERS:
			return {
				...state,
				walletsFilters: {
					...state.walletsFilters,
					...action.payload.filters,
				},
			};
		case CLEAR_WALLETS_FILTERS:
			return {
				...state,
				walletsFilters: {
					...initialState.walletsFilters,
					leverageFrom: '',
					leverageTo: '',
				},
			};
		case SET_AFFILIATE_FILTERS:
			return {
				...state,
				affiliateFilters: {
					...state.affiliateFilters,
					...action.payload.filters,
				},
			};
		case CLEAR_AFFILIATE_FILTERS:
			return {
				...state,
				affiliateFilters: {
					...initialState.affiliateFilters,
				},
			};
		case SET_LEADS_FILTERS:
			return {
				...state,
				leadsFilters: {
					...state.leadsFilters,
					...action.payload.filters,
				},
			};
		case CLEAR_LEADS_FILTERS:
			return {
				...state,
				leadsFilters: {
					...initialState.leadsFilters,
					salesPeople: state.leadsFilters.salesPeople.map((s) => {
						return {
							...s,
							enabled: false,
						};
					}),
					sources: state.leadsFilters.sources.map((s) => {
						return {
							...s,
							enabled: false,
						};
					}),
				},
			};
		case SET_AUDIT_LOGS_FILTERS:
			return {
				...state,
				auditLogsFilters: {
					...state.auditLogsFilters,
					...action.payload.filters,
				},
			};
		case CLEAR_AUDIT_LOGS_FILTERS:
			return {
				...state,
				auditLogsFilters: {
					...initialState.auditLogsFilters,
				},
			};
		case SET_RISK_MANAGEMENT_FILTERS:
			return {
				...state,
				riskManagementFilters: {
					...state.riskManagementFilters,
					...action.payload.filters,
				},
			};
		case CLEAR_RISK_MANAGEMENT_FILTERS: {
			return {
				...state,
				riskManagementFilters: {
					...initialState.riskManagementFilters,
				},
			};
		}
		case SET_PAYOUT_REQUEST_FILTERS:
			return {
				...state,
				payoutRequestFilters: {
					...state.customersFilters,
					...action.payload.filters,
				},
			};
		case CLEAR_PAYOUT_REQUEST_FILTERS:
			return {
				...state,
				payoutRequestFilters: {
					...initialState.payoutRequestFilters,
				},
			};
		default:
			return state;
	}
}

export default filtersReducer;
